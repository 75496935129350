import {
  ActionLinkRoutes,
  AuthRoutes,
  BookingRoutes,
  BookingGroupRoutes,
  CampaignRoutes,
  CampaignTemplateRoutes,
  CampaignVersionRoutes,
  InfoSchemaRoutes,
  PlacementRoutes,
  UserRoutes,
  PublicClientFileRoutes,
  NotificationRoutes,
  PublicationGroupsRoutes,
  ExternalRoutes,
  OrganisationRoutes,
  WorkspaceRoutes,
  ClientRoutes,
  Auth0Routes,
  WorkspaceMemberRoutes,
  UserGroupRoutes,
  OrgMemberRoutes,
  UploadRoutes,
  AdminRoutes,
} from "./entities";
import { CampaignUserRoutes } from "./entities/campaignUserDetails/routes";

export const coreRoutes = {
  applicationVersion: "applicationVersion",
  healthCheck: "healthcheck",
  campaignTemplates: "campaigntemplates",
  campaignUsers: "campaignusers",
  infoSchemas: "infoschemas",
  placements: "placements",
  campaigns: "campaigns",
  sharedCampaigns: "sharedcampaigns",
  bookings: "bookings",
  bookingGroups: "groups",
  users: "users",
  actionLinks: "actionlinks",
  campaignVersion: "campaignversion",
  publicClientFile: "publicfile",
  notifications: "notifications",
  publicationGroups: "publicationGroups",
  external: "external",
  organisations: "organisations",
  workspaces: "workspaces",
  clients: "clients",
  auth0: "auth0",
  admin: "admin",
  orgMember: "orgmember",
  workspaceMember: "wsmember",
  userGroup: "usergroup",
  auth: "auth",
  authorize: "authorize", // TODO: /authorize can be deprecated with auth-server
  upload: "upload",
} as const;

export type RouteBuilder =
  | ({ root: (typeof coreRoutes)["admin"] } & AdminRoutes)
  | ({ root: (typeof coreRoutes)["auth"] } & AuthRoutes)
  | ({ root: (typeof coreRoutes)["authorize"] } & AuthRoutes) // TODO: /authorize can be deprecated with auth-server
  | ({
      root: (typeof coreRoutes)["campaignTemplates"];
    } & CampaignTemplateRoutes)
  | ({
      root: (typeof coreRoutes)["campaignVersion"];
    } & CampaignVersionRoutes)
  | ({ root: (typeof coreRoutes)["actionLinks"] } & ActionLinkRoutes)
  | ({ root: (typeof coreRoutes)["infoSchemas"] } & InfoSchemaRoutes)
  | ({ root: (typeof coreRoutes)["placements"] } & PlacementRoutes)
  | ({ root: (typeof coreRoutes)["campaigns"] } & CampaignRoutes)
  | ({ root: (typeof coreRoutes)["campaignUsers"] } & CampaignUserRoutes)
  | ({ root: (typeof coreRoutes)["bookingGroups"] } & BookingGroupRoutes)
  | ({ root: (typeof coreRoutes)["bookings"] } & BookingRoutes)
  | ({ root: (typeof coreRoutes)["users"] } & UserRoutes)
  | ({ root: (typeof coreRoutes)["publicClientFile"] } & PublicClientFileRoutes)
  | ({ root: (typeof coreRoutes)["notifications"] } & NotificationRoutes)
  | ({
      root: (typeof coreRoutes)["publicationGroups"];
    } & PublicationGroupsRoutes)
  | ({ root: (typeof coreRoutes)["organisations"] } & OrganisationRoutes)
  | ({ root: (typeof coreRoutes)["workspaces"] } & WorkspaceRoutes)
  | ({ root: (typeof coreRoutes)["clients"] } & ClientRoutes)
  | ({ root: (typeof coreRoutes)["notifications"] } & NotificationRoutes)
  | ({ root: (typeof coreRoutes)["external"] } & ExternalRoutes)
  | ({ root: (typeof coreRoutes)["orgMember"] } & OrgMemberRoutes)
  | ({ root: (typeof coreRoutes)["workspaceMember"] } & WorkspaceMemberRoutes)
  | ({ root: (typeof coreRoutes)["userGroup"] } & UserGroupRoutes)
  | ({ root: (typeof coreRoutes)["auth0"] } & Auth0Routes)
  | ({ root: (typeof coreRoutes)["upload"] } & UploadRoutes);

export const urlBuilder = (
  route: RouteBuilder,
  isController: boolean = false
): string => {
  const getSubPart = (): string => {
    if ("tailParam" in route) {
      return `${route.sub ? `${route.sub}/` : ""}${route.tailParam}`;
    }
    return `${route.sub}`;
  };

  const getRouteWithQueryParams = (curRoute: string): string => {
    if ("queryParams" in route && route.queryParams && !isController) {
      return `${curRoute}?${Object.entries(route.queryParams)
        .filter((entry) => entry[1] !== undefined)
        .map(([key, value]) =>
          Array.isArray(value)
            ? value
                .map(
                  (v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`
                )
                .join("&")
            : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")}`;
    }
    return curRoute;
  };

  const subPart = getSubPart();
  const allParts = getRouteWithQueryParams(subPart);

  if (isController) {
    return allParts;
  }

  return `${route.root}/${allParts}`;
};
