import { debounce } from "@mui/material";
import useResizeObserver from "@react-hook/resize-observer";
import React, { RefObject, useCallback } from "react";

export const useSize = (
  target: RefObject<HTMLDivElement | HTMLSpanElement>
) => {
  const [size, setSize] = React.useState<DOMRect | null>(null);

  React.useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  const handleResize = useCallback(
    debounce((entry) => setSize(entry.contentRect)),
    []
  );

  useResizeObserver(target, handleResize);
  return size;
};
